import Vue from 'vue'
import VueRouter from 'vue-router'

const hyperParams = () => import('@/views/frontend/landing-page/components/hyperParams.vue');
const Patterns = () => import('@/views/frontend/landing-page/components/Patterns.vue');
const rewardFunctions = () => import('@/views/frontend/landing-page/components/rewardFunctions');
const ContactUs = () => import('@/views/frontend/landing-page/components/ContactUs')
// Adding layouts router.
// Frontend layout
const LandingPage = () => import('@/layouts/frontend/LandingPage')

// Frontend pages
const Page = () => import('@/views/frontend/landing-page/Page')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landingpage',
    component: LandingPage,
    children: [
      {
        path: '/',
        name: 'frontend.page',
        meta: { name: 'Landing Page' },
        component: Page
      },
      {
        path: '/hyper-parameters',
        name: 'HyperParams', 
        meta: { name: 'Hyperparameter' },
        component: hyperParams
      },
      {
        path: '/patterns',
        name: 'Patterns',
        meta: { name: 'Patterns' },
        component: Patterns,
      },
      {
        path: '/reward-functions',
        name: 'rewardFunctions',
        meta: {name: 'rewardFunctions'},
         component: rewardFunctions,
      },
      {
        path: '/contactus',
        name: 'ContactUs',
        meta: { name: 'ContactUs'},
         component: ContactUs,
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
