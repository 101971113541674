import Vue from 'vue'

Vue.component('card', require('../components/cards/card').default)
// Vue.component('Lottie', require('../components/core/lottie/Lottie').default)
Vue.component('Progressbar', require('../components/progressbar/Progressbar').default)
Vue.component('Slick', require('../components/slider/Slick').default)
Vue.component('tab-nav', require('../components/tab/tab-nav').default)
Vue.component('tab-nav-items', require('../components/tab/tab-nav-items').default)
Vue.component('tab-content', require('../components/tab/tab-content').default)
Vue.component('tab-content-item', require('../components/tab/tab-content-item').default)
Vue.component('VTwoSectionTitle', require('../components/frontend/title/VTwoSectionTitle').default)
Vue.component('VTwoPost', require('../components/frontend/VTwoPost').default)
Vue.component('TitleSection', require('../components/frontend/title/TitleSection').default)
Vue.component('ServiceStyle', require('../components/frontend/ServiceStyle').default)
Vue.component('CardStyle1', require('../components/frontend/cards/CardStyle1').default)
Vue.component('CardStyle2', require('../components/frontend/cards/CardStyle2').default)
Vue.component('CardStyle3', require('../components/frontend/cards/CardStyle3').default)
Vue.component('PartnerSection', require('../components/frontend/PartnerSection').default)
Vue.component('AboutSection', require('../components/frontend/AboutSection').default)
Vue.component('CardStyle4', require('../components/frontend/cards/CardStyle4').default)
Vue.component('CardStyle5', require('../components/frontend/cards/CardStyle5').default)
Vue.component('VTwoCaseStudies', require('../components/frontend/carsol/VTwoCaseStudies.vue').default)
Vue.component('Slick', require('../components/slider/Slick').default)
Vue.component('HoverMenu', require('../components/menustyle/HoverMenu').default)
Vue.component('ModeSwitch', require('../components/mode/ModeSwitch').default)
Vue.component('Select2', require('../components/form/select2/Select2').default)
// Vue.component('TimeLine', require('../components/tim4eline/TimeLine').default)
